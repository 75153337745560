import { TEXT_INPUT } from '@/views/components/DynamicForm/constants'
import { getValueFromGivenObjectByKey } from '@/@core/utils/utils'
import mainConfig from '@/views/main/orders/config'

const { ORDERS_STATUS_VOID } = mainConfig()

export default function () {
  const ORDER_INFORMATION_TITLE = 'Order Information'
  const PO_REJECTED_STATUS = ORDERS_STATUS_VOID.status

  const orderInformationObjectForRender = [
    {
      key: 'supplier.cust_name_dba',
      label: 'Supplier',
    },
    {
      key: 'dispatch_warehouse.label',
      label: 'Dispatch Warehouse',
    },
    {
      key: 'required_by',
      label: 'Required by',
    },
    {
      key: 'dispatch_method.name',
      label: 'Dispatch Method',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'billing_contact.first_name', '—')} 
      ${getValueFromGivenObjectByKey(data, 'billing_contact.last_name', '')} 
      `,
      label: 'Billing Contact',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'dispatch_contact.firstname', '—')} 
      ${getValueFromGivenObjectByKey(data, 'dispatch_contact.lastname', '')} 
      `,
      label: 'Dispatch Contact',
    },
    {
      key: 'billing_contact.phone_one',
      label: 'Billing Phone',
    },
    {
      key: 'dispatch_contact.phone',
      label: 'Dispatch Phone',
    },
    {
      key: 'billing_contact.email',
      label: 'Billing Email',
    },
    {
      key: 'dispatch_contact.email',
      label: 'Dispatch Email',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'billing_address.street', '—')}, 
      ${getValueFromGivenObjectByKey(data, 'billing_address.line2', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.city', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.state.code', '')} 
      ${getValueFromGivenObjectByKey(data, 'billing_address.zip', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.country.three_letter', '')}
      `,
      label: 'Billing Address',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.street', '—')},
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.city', '—')}, 
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.state.name', '')} 
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.zip_code', '—')}, 
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.country.two_letter', '')} 
      `,
      label: 'Shipping Address',
    },

  ]

  const PURCHASE_ORDER_FOR_SUPPLIER_TITLE_ATTACHMENT = 'Attachments'
  const RFQ_SENT_BOTTOM_TITLE_EXTERNAL_ORDER_NOTES = 'External Order Notes (visible to the supplier)'
  const RFQ_SENT_BOTTOM_TITLE_ATTACHMENT_INTERNAL_ORDER_NOTES = 'Internal Order Notes (not visible to the supplier)'

  const orderInformationObjectForRenderBottom = [
    {
      key: 'internal_order_reference',
      label: RFQ_SENT_BOTTOM_TITLE_ATTACHMENT_INTERNAL_ORDER_NOTES,
    },
    {
      key: 'attachments',
      label: PURCHASE_ORDER_FOR_SUPPLIER_TITLE_ATTACHMENT,
    },
    {
      key: 'external_order_notes',
      label: RFQ_SENT_BOTTOM_TITLE_EXTERNAL_ORDER_NOTES,
    },
  ]
  const STOCK_ITEMS_TITLE = 'Items'

  const StockItemTableColumns = [
    {
      key: 'action',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '10%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '35%', paddingLeft: '4.6%' },
    },
    {
      key: 'amount',
      label: 'Units',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'price',
      label: 'Price',
      thStyle: { width: '8%' },
    },
    {
      key: 'discount',
      label: 'Discount',
      thStyle: { width: '10%' },
    },
    {
      key: 'subtotal',
      label: 'Subtotal',
      thStyle: { width: '10%' },
    },
    {
      key: 'taxes',
      label: 'Taxes',
      thStyle: { width: '10%' },
    },
    {
      key: 'total',
      label: 'TOTAL',
      thStyle: { width: '10%', textAlign: 'right' },
    },
  ]

  const StockItemForPrintTableColumns = [
    {
      key: 'action',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '10%' },
    },
    {
      key: 'mpn',
      label: 'MPN',
      thStyle: { width: '10%' },
    },
    {
      key: 'supplier_sku',
      label: 'Supplier SKU',
      thStyle: { width: '15%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '30%' },
    },
    {
      key: 'price',
      label: 'Price',
      thStyle: { width: '10%' },
    },
    {
      key: 'amount',
      label: 'Units',
      thStyle: { width: '5%', textAlign: 'center' },
    },

    {
      key: 'discount',
      label: 'Disc.',
      thStyle: { width: '5%' },
    },
    {
      key: 'subtotal',
      label: 'Subtotal',
      thStyle: { width: '10%' },
    },
    {
      key: 'taxes',
      label: 'Taxes',
      thStyle: { width: '10%' },
    },
    {
      key: 'total',
      label: 'TOTAL',
      thStyle: { width: '10%', textAlign: 'right' },
    },
  ]

  const PO_FOR_SUPPLIER_BTN_PRINT = {
    title: 'Print',
    action: '',
  }

  const PO_FOR_SUPPLIER_BTN_UPLOAD_INVOICE = {
    title: 'Upload Invoice',
    action: '',
  }
  const PO_FOR_SUPPLIER_BTN_CLOSE_ORDER = {
    title: 'Close Order',
    action: 'close_order',
  }

  const PO_FOR_SUPPLIER_BTN_VOID_ORDER = {
    title: 'Void order',
    action: 'Void_order',
  }
  const PO_FOR_SUPPLIER_BTN_BACK_RFQ_DRAFT = {
    title: 'Back to RFQ Draft',
    action: 'back_rfq_draft',
  }

  const PO_FOR_SUPPLIER_BTN_CHANGE_QUOTE = {
    title: 'Change Quote',
    action: 'change_quote',
  }

  const notesFields = {
    internal_order_reference: {
      type: TEXT_INPUT,
      label: 'Internal RFQ Notes',
    },
    external_order_notes: {
      type: TEXT_INPUT,
      label: 'External RFQ Notes (visible to the supplier)',
    },
    external_po_notes: {
      type: TEXT_INPUT,
      label: 'External PO Notes (visible to the supplier)',
    },
  }

  const uploadAttachmentTableCol = [
    {
      key: 'action',
      label: '',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '10%',
      },
    },
    {
      key: 'name',
      label: 'File Name',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '30%',
      },
    },
    {
      key: 'date',
      label: 'Date',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '20%',
      },
    },
    {
      key: 'type',
      label: 'Type',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '20%',
      },
    },
    {
      key: 'category',
      label: 'Category',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '20%',
      },
    },
  ]

  const TOTALS_NOTES_TITLE = 'Notes'
  const TOTALS_TOTAL_TOTALS = 'Totals'

  const TOTALS_TOTAL_PRICE_TITLE = 'TOTAL'
  const TOTALS_ITEMS_TOTAL_PRICE_TITLE = 'Items'
  const TOTALS_TAXES_TOTAL_PRICE_TITLE = 'Taxes'
  const TOTALS_DISCOUNT_TOTAL_PRICE_TITLE = 'Discounts'
  const TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE = 'Other Charges'

  const PO_FOR_SUPPLIER_UPLOAD_INVOICE_TITLE = 'Upload Invoice'
  const PO_FOR_SUPPLIER_UPLOAD_INVOICE_DRAG_ZONE_TITLE = 'Drop Files Here to Upload'

  return {
    notesFields,
    STOCK_ITEMS_TITLE,
    PO_REJECTED_STATUS,
    TOTALS_NOTES_TITLE,
    TOTALS_TOTAL_TOTALS,
    StockItemTableColumns,
    StockItemForPrintTableColumns,
    ORDER_INFORMATION_TITLE,
    TOTALS_TOTAL_PRICE_TITLE,
    uploadAttachmentTableCol,
    PO_FOR_SUPPLIER_BTN_PRINT,
    TOTALS_ITEMS_TOTAL_PRICE_TITLE,
    TOTALS_TAXES_TOTAL_PRICE_TITLE,
    PO_FOR_SUPPLIER_BTN_VOID_ORDER,
    orderInformationObjectForRender,
    PO_FOR_SUPPLIER_BTN_CLOSE_ORDER,
    PO_FOR_SUPPLIER_BTN_CHANGE_QUOTE,
    TOTALS_DISCOUNT_TOTAL_PRICE_TITLE,
    PO_FOR_SUPPLIER_BTN_UPLOAD_INVOICE,
    PO_FOR_SUPPLIER_BTN_BACK_RFQ_DRAFT,
    PO_FOR_SUPPLIER_UPLOAD_INVOICE_TITLE,
    TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE,
    orderInformationObjectForRenderBottom,
    RFQ_SENT_BOTTOM_TITLE_EXTERNAL_ORDER_NOTES,
    PURCHASE_ORDER_FOR_SUPPLIER_TITLE_ATTACHMENT,
    PO_FOR_SUPPLIER_UPLOAD_INVOICE_DRAG_ZONE_TITLE,
    RFQ_SENT_BOTTOM_TITLE_ATTACHMENT_INTERNAL_ORDER_NOTES,
  }
}
