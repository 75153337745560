<template>
  <div class="form-wrapper">
    <h3
      class="font-weight-bolder mb-1"
      style="color: #646464"
    >
      {{ $t(STOCK_ITEMS_TITLE) }}
    </h3>
    <b-table
      :items="products"
      :fields="StockItemTableColumns"
      class="purchase-order-stock-items"
      :show-empty="true"
      :empty-text="'None found'"
      foot-clone
    >
      <template #cell(action)="{ item: { id }}">
        <feather-icon
          icon="LStockEyeIcon"
          size="32"
          class="cursor-pointer"
          @click="gotoNewPage({name: 'home-catalog-general-info', params: { id } }, $event, true)"
        />
      </template>
      <template #cell(name)="{ item: { name } }">
        <feather-icon
          icon="LTruckIcon"
          size="24"
          class="feather-trash-icon ORDERS_STATUS_READY"
        /><span>{{ $t(name) }}</span>
      </template>
      <template #cell(price)="{ item: { price } }">
        {{ formatNumberToCurrencyView(price) }}
      </template>
      <template #cell(discount)="{ item: { discount} }">
        {{ formatNumberToCurrencyView(discount) }}
      </template>
      <template #cell(amount)="{ item: { amount } }">
        <div class="text-center">
          {{ amount }}
        </div>
      </template>
      <template #cell(subtotal)="{ item: { discount, price, amount } }">
        {{ formatNumberToCurrencyView((price *amount) - discount ) }}
      </template>
      <template #cell(taxes)="{ item: { price, tax, amount } }">
        {{ formatNumberToCurrencyView((price *amount) * tax / 100) }}
      </template>
      <template #cell(total)="{ item: { discount, price, tax,taxes, amount} }">
        <div
          class="text-right"
          style="padding-right: 6px"
        >
          {{ formatNumberToCurrencyView((price * amount) + taxes- discount) }}
        </div>
      </template>
      <!--   Footer   -->
      <template #foot(sku)>
        {{ ' ' }}
      </template>
      <template #foot(price)>
        {{ ' ' }}
      </template>
      <template #foot(name)>
        {{ $t(TOTALS_TOTAL_PRICE_TITLE) }}S
      </template>
      <template #foot(amount)>
        {{ getTotalOF('amount') }}
      </template>
      <template #foot(discount)>
        <span />
      </template>
      <template #foot(subtotal)>
        {{ formatNumberToCurrencyView(totalSumOfSubtotals) }}
      </template>
      <template #foot(taxes)>
        {{ formatNumberToCurrencyView(totalSumOfTaxes) }}
      </template>
      <template #foot(total)>
        {{ formatNumberToCurrencyView(totalSumOfItem) }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { formatNumberToCurrencyView, sumTotalAmountOfGivenArrayByProperty } from '@core/utils/utils'
import { BTable } from 'bootstrap-vue'
import config from './config'
import mainConfig from '../../../config'

export default {
  name: 'StockItems',
  components: {
    BTable,
  },
  computed: {
    products() {
      return this.$store.state[this.MODULE_NAME].quote.products
    },
    totalSumOfDiscounts() {
      return sumTotalAmountOfGivenArrayByProperty(this.products.map(({ price, discount }) => ({ totalDiscount: price * discount / 100 })), 'totalDiscount')
    },
    totalSumOfSubtotals() {
      return sumTotalAmountOfGivenArrayByProperty(this.products.map(({ price, discount, amount }) => ({ totalSumOfSubtotal: (price * amount) - discount })), 'totalSumOfSubtotal')
    },
    totalSumOfTaxes() {
      return sumTotalAmountOfGivenArrayByProperty(this.products.map(({ taxes }) => ({ totalSumOfTaxes: taxes })), 'totalSumOfTaxes')
    },
    totalSumOfItem() {
      return sumTotalAmountOfGivenArrayByProperty(this.products.map(({
        price, discount, amount, taxes,
      }) => ({ totalSumOfItem: (price * amount) + taxes - discount })), 'totalSumOfItem')
    },
  },
  methods: {
    getTotalOF(item) {
      return this.sumTotalAmountOfGivenArrayByProperty(this.products, item)
    },
  },
  setup() {
    const { STOCK_ITEMS_TITLE, StockItemTableColumns, TOTALS_TOTAL_PRICE_TITLE } = config()
    const { MODULE_NAME } = mainConfig()

    return {
      MODULE_NAME,
      STOCK_ITEMS_TITLE,
      StockItemTableColumns,
      TOTALS_TOTAL_PRICE_TITLE,
      formatNumberToCurrencyView,
      sumTotalAmountOfGivenArrayByProperty,
    }
  },
}
</script>
<style lang="scss">
.purchase-order-stock-items {
  & tfoot tr th {
    background-color: transparent !important;
    border-top: none;
  }
}
</style>
