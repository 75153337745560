<template>
  <div class="form-wrapper">
    <h3
      class="font-weight-bolder mb-1"
      style="color: #646464"
    >
      {{ $t(STOCK_ITEMS_TITLE) }}
    </h3>
    <b-table
      :items="products"
      :fields="StockItemForPrintTableColumns"
      class="purchase-order-stock-items"
      :show-empty="true"
      :empty-text="'None found'"
      foot-clone
    >
      <template #cell(action)="{ item: { id }}">
        <feather-icon
          icon="LEyeIcon"
          size="24"
          class="cursor-pointer border-dotted feather-trash-icon defaultIconColor"
          @click="gotoNewPage({name: 'home-catalog-general-info', params: { id } }, $event, true)"
        />
      </template>
      <template #cell(mpn)="{ item }">
        {{ getValueFromGivenObjectByKey(item, 'mpn' ) }}
      </template>
      <template #cell(supplier_sku)="{ item }">
        {{ getValueFromGivenObjectByKey(item, 'supplier_sku' ) }}
      </template>
      <template #cell(amount)="{ item }">
        <div class="text-center">
          {{ getValueFromGivenObjectByKey(item, 'amount' ) }}
        </div>
      </template>
      <template #cell(subtotal)="{ item: { discount, price } }">
        {{ formatNumberToCurrencyView(price - (price * discount / 100)) }}
      </template>
      <template #cell(discount)="{ item: { discount, price } }">
        {{ formatNumberToCurrencyView(price * discount / 100) }}
      </template>
      <template #cell(taxes)="{ item: { discount, price, tax } }">
        {{ formatNumberToCurrencyView((price - (price * discount / 100)) * tax / 100) }}
      </template>
      <template #cell(total)="{ item: { discount, price, tax } }">
        <div
          class="text-right"
          style="padding-right: 6px"
        >
          {{ formatNumberToCurrencyView((price - (price * discount / 100)) + (price - (price * discount / 100)) * tax / 100) }}
        </div>
      </template>
      <!--   Footer   -->
      <template #foot(sku)>
        {{ ' ' }}
      </template>
      <template #foot(mpn)>
        {{ ' ' }}
      </template>
      <template #foot(supplier_sku)>
        {{ ' ' }}
      </template>
      <template #foot(price)>
        {{ ' ' }}
      </template>
      <template #foot(name)>
        {{ $t(TOTALS_TOTAL_PRICE_TITLE) }}S
      </template>
      <template #foot(amount)>
        {{ getTotalOF('amount') }}
      </template>
      <template #foot(discount)>
        {{ formatNumberToCurrencyView(totalSumOfDiscounts) }}
      </template>
      <template #foot(subtotal)>
        {{ formatNumberToCurrencyView(totalSumOfSubtotals) }}
      </template>
      <template #foot(taxes)>
        {{ formatNumberToCurrencyView(totalSumOfTaxes) }}
      </template>
      <template #foot(total)>
        {{ formatNumberToCurrencyView(totalSumOfItem) }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { formatNumberToCurrencyView, sumTotalAmountOfGivenArrayByProperty, getValueFromGivenObjectByKey } from '@core/utils/utils'
import { BTable } from 'bootstrap-vue'
import config from './config'
import mainConfig from '../../../config'

export default {
  name: 'StockItemsForPrint',
  components: {
    BTable,
  },
  computed: {
    products() {
      return this.$store.state[this.MODULE_NAME].quote.products
    },
    totalSumOfDiscounts() {
      return sumTotalAmountOfGivenArrayByProperty(this.products.map(({ price, discount }) => ({ totalDiscount: price * discount / 100 })), 'totalDiscount')
    },
    totalSumOfSubtotals() {
      return sumTotalAmountOfGivenArrayByProperty(this.products.map(({ price, discount }) => ({ totalSumOfSubtotal: price - (price * discount / 100) })), 'totalSumOfSubtotal')
    },
    totalSumOfTaxes() {
      return sumTotalAmountOfGivenArrayByProperty(this.products.map(({ price, discount, tax }) => ({ totalSumOfTaxes: (price - (price * discount / 100)) * tax / 100 })), 'totalSumOfTaxes')
    },
    totalSumOfItem() {
      return sumTotalAmountOfGivenArrayByProperty(this.products.map(({ price, discount, tax }) => ({ totalSumOfItem: (price - (price * discount / 100)) + ((price - (price * discount / 100)) * tax / 100) })), 'totalSumOfItem')
    },
  },
  methods: {
    getTotalOF(item) {
      return this.sumTotalAmountOfGivenArrayByProperty(this.products, item)
    },
    getValueFromGivenObjectByKey,
  },
  setup() {
    const { STOCK_ITEMS_TITLE, StockItemForPrintTableColumns, TOTALS_TOTAL_PRICE_TITLE } = config()
    const { MODULE_NAME } = mainConfig()

    return {
      MODULE_NAME,
      STOCK_ITEMS_TITLE,
      StockItemForPrintTableColumns,
      TOTALS_TOTAL_PRICE_TITLE,
      formatNumberToCurrencyView,
      sumTotalAmountOfGivenArrayByProperty,
    }
  },
}
</script>
<style lang="scss">
.purchase-order-stock-items {
  & tfoot tr th {
    background-color: transparent !important;
    border-top: none;
  }
}
</style>
