<script src="../../../../../../../../store/main/orders/purchase-cr/actions.js"></script>
<template>
  <portal to="body-footer">
    <div class="d-flex mt-2 pb-1 justify-content-between">
      <div>
        <!--            v-if="!isFormChanged || !address.id"-->
        <b-button
          v-if="true"
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="$router.push({name: 'home-orders-purchase-cr-list'})"
        >
          {{ $t('Back to List') }}
        </b-button>
        <b-button
          v-else
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
        >
          <!--            @click="loader"-->
          <feather-icon
            icon="LCancelIcon"
            size="16"
          />
          {{ $t('Cancel') }}
        </b-button>
      </div>
      <div
        class="d-flex"
        style="gap: 8px"
      >
        <!--    Void Order    -->
        <b-button
          v-if="poRejectedStatus"
          disabled
          style="width: 135px; height: 40px; gap: 10px; background: #6f1214 !important;"
          class="font-medium-1 p-0 d-flex justify-content-center align-items-center"
          @click="submitByAction(PO_FOR_SUPPLIER_BTN_VOID_ORDER)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === PO_FOR_SUPPLIER_BTN_VOID_ORDER.action"
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(PO_FOR_SUPPLIER_BTN_VOID_ORDER.title) }}
          </span>
        </b-button>
        <!--   PO CHANGE QUOTE Handler     -->
        <!--        <b-button-->
        <!--          v-if="isOrderClosed"-->
        <!--          variant="secondary"-->
        <!--          :disabled="loadingFor.isLoading"-->
        <!--          class="font-medium-1 font-weight-bold d-flex justify-content-center align-items-center"-->
        <!--          style="width: 205px; height: 42px; gap: 8px"-->
        <!--          @click="submitByAction(PO_FOR_SUPPLIER_BTN_CHANGE_QUOTE)"-->
        <!--        >-->
        <!--          <b-spinner-->
        <!--            v-if="loadingFor.isLoading && loadingFor.action === PO_FOR_SUPPLIER_BTN_CHANGE_QUOTE.action"-->
        <!--            small-->
        <!--            variant="secondary"-->
        <!--          />-->
        <!--          <div v-else>-->
        <!--            <feather-icon icon="LLoadIcon" />-->
        <!--            <span>-->
        <!--              {{ $t(PO_FOR_SUPPLIER_BTN_CHANGE_QUOTE.title) }}-->
        <!--            </span>-->
        <!--          </div>-->
        <!--        </b-button>-->
        <!--   Print Handler     -->
        <b-button
          :disabled="loadingFor.isLoading"
          variant="secondary"
          class="font-medium-1 font-weight-bold px-3"
          @click="printRFQForSupplierTemplate"
        >
          <Printer>
            <div
              id="pofs"
              hidden
            >
              <POForSupplierPrintTemplate />
            </div>
          </Printer>
          <feather-icon icon="LPrintIcon" />
          {{ $t(PO_FOR_SUPPLIER_BTN_PRINT.title) }}
        </b-button>

        <!--    Back To RFQ Draft    -->
        <b-button
          v-if="poRejectedStatus"
          :disabled="loadingFor.isLoading"
          variant="success"
          class="font-medium-1 px-2 d-flex justify-content-center align-items-center"
          style="width: 200px; height: 40px"
          @click="submitByAction(PO_FOR_SUPPLIER_BTN_BACK_RFQ_DRAFT)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === PO_FOR_SUPPLIER_BTN_BACK_RFQ_DRAFT.action"
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(PO_FOR_SUPPLIER_BTN_BACK_RFQ_DRAFT.title) }}
          </span>
        </b-button>

        <!--   Upload Invoice Handler     -->
        <b-button
          v-if="!poRejectedStatus && !uploadInvoiceCollapseIsOpen && !isOrderClosed"
          variant="success"
          class="font-medium-1 font-weight-bold px-3 d-flex align-items-center"
          style="gap: 8px"
          @click="uploadInvoiceCollapseHandler(true)"
        >
          <feather-icon
            icon="LCreditCardIconFill"
            size="18"
          />
          <span>
            {{ $t(PO_FOR_SUPPLIER_BTN_UPLOAD_INVOICE.title) }}
          </span>
        </b-button>

        <!--   CLOSE ORDER Handler     -->
        <b-button
          v-if="uploadInvoiceCollapseIsOpen || isOrderClosed"
          variant="success"
          :disabled="isOrderClosed || loadingFor.isLoading"
          class="font-medium-1 font-weight-bold d-flex justify-content-center align-items-center"
          style="width: 215px; height: 42px; gap: 8px"
          @click="closeOrderHandler(PO_FOR_SUPPLIER_BTN_CLOSE_ORDER.action)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === PO_FOR_SUPPLIER_BTN_CLOSE_ORDER.action"
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(PO_FOR_SUPPLIER_BTN_CLOSE_ORDER.title) }}
          </span>
        </b-button>

      </div>
    </div>
  </portal>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import jsonToFormData from '@/libs/jsonToFormData'
import Printer from '@/views/components/Printer/Printer.vue'
import POForSupplierPrintTemplate from './components/POForSupplierPrintTemplate.vue'
import config from './config'
import mainConfig from '../../../config'

export default {
  name: 'FormBottom',
  components: {
    POForSupplierPrintTemplate,
    BButton,
    BSpinner,
    Printer,
  },
  data() {
    return {
      loadingFor: {
        isLoading: false,
        action: null,
      },
    }
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
    poRejectedStatus() {
      return this.quote.status === this.PO_REJECTED_STATUS
    },
    uploadInvoiceCollapseIsOpen() {
      return this.$store.state[this.MODULE_NAME].uploadInvoiceCollapseIsOpen
    },
    POInvoice() {
      return this.$store.state[this.MODULE_NAME].POInvoice
    },
    isOrderClosed() {
      return this.quote.status === this.ORDERS_STATUS_READY_STATUS && this.quote.state === this.ORDERS_STATE_CLOSED_ORDER
    },
  },
  methods: {
    redirectToRFQSend(id) {
      this.$router.push({ name: 'direct-request-for-quote', params: { id } })
    },
    uploadInvoiceCollapseHandler(bool) {
      this.$store.commit(`${this.MODULE_NAME}/SET_UPLOAD_INVOICE_COLLAPSE_IS_OPEN`, bool)
    },
    closeOrderHandler(action) {
      this.loadingFor = {
        isLoading: true,
        action,
      }
      this.$emit('isLoading', this.loadingFor.isLoading)

      const convertedToFormData = jsonToFormData({ id: this.quote.id, attachments: [this.POInvoice] })
      // this.$store.commit(`${this.MODULE_NAME}/SET_UPLOAD_INVOICE_COLLAPSE_IS_OPEN`, bool)
      this.sendNotification(this, convertedToFormData, `${this.MODULE_NAME}/uploadInvoice`, 'Invoice Uploaded Successfully')
        .then(() => {

        }).finally(() => {
          this.loadingFor = {
            isLoading: false,
            action: null,
          }
          // this.$router.push({ name: 'home-orders-purchase-cr-list' })
          this.$emit('reload')
          this.$store.commit(`${this.MODULE_NAME}/SET_UPLOAD_INVOICE_COLLAPSE_IS_OPEN`, false)
          this.$store.commit(`${this.MODULE_NAME}/SET_UPLOADED_PO_INVOICE`, {})
        })
    },
    printRFQForSupplierTemplate() {
      this.$htmlToPaper('pofs', {
        autoClose: false,
      })
    },
    submitByAction(item) {
      const { id } = this.quote
      const { action, successMsg } = item
      this.$emit('onSubmitSuccess')

      this.loadingFor = {
        isLoading: true,
        action,
      }

      this.sendNotification(this, { id, body: { id, action } }, `${this.MODULE_NAME}/patch`, successMsg)
        .then(() => {
          this.$emit('clear')
          this.redirectToRFQSend(id, action)
        }).finally(() => {
          this.loadingFor = {
            isLoading: false,
            action: null,
          }
        })
    },
  },
  setup() {
    const {
      PO_REJECTED_STATUS,
      PO_FOR_SUPPLIER_BTN_PRINT,
      PO_FOR_SUPPLIER_BTN_VOID_ORDER,
      PO_FOR_SUPPLIER_BTN_CLOSE_ORDER,
      PO_FOR_SUPPLIER_BTN_CHANGE_QUOTE,
      PO_FOR_SUPPLIER_BTN_BACK_RFQ_DRAFT,
      PO_FOR_SUPPLIER_BTN_UPLOAD_INVOICE,
    } = config()

    const {
      MODULE_NAME,
      ORDERS_STATE_CLOSED_ORDER,
      ORDERS_STATUS_READY_STATUS,
    } = mainConfig()

    return {
      MODULE_NAME,
      PO_REJECTED_STATUS,
      ORDERS_STATE_CLOSED_ORDER,
      PO_FOR_SUPPLIER_BTN_PRINT,
      ORDERS_STATUS_READY_STATUS,
      PO_FOR_SUPPLIER_BTN_VOID_ORDER,
      PO_FOR_SUPPLIER_BTN_CLOSE_ORDER,
      PO_FOR_SUPPLIER_BTN_CHANGE_QUOTE,
      PO_FOR_SUPPLIER_BTN_BACK_RFQ_DRAFT,
      PO_FOR_SUPPLIER_BTN_UPLOAD_INVOICE,
    }
  },
}
</script>
