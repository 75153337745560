var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-wrapper"},[_c('h3',{staticClass:"font-weight-bolder mb-1"},[_vm._v(" "+_vm._s(_vm.$t(_vm.PURCHASE_ORDER_FOR_SUPPLIER_TITLE_ATTACHMENT))+" ")]),(!_vm.isLoading)?_c('b-table',{staticClass:"upload-attachment-table",attrs:{"items":_vm.receiveAttachments,"fields":_vm.uploadAttachmentTableCol,"show-empty":true,"empty-text":'None found'},scopedSlots:_vm._u([{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"8px"}},[_c('a',{on:{"click":function($event){return _vm.showAttachment(data.item)}}},[_c('feather-icon',{staticClass:"cursor-pointer border-dotted feather-eye-icon",staticStyle:{"padding":"5px"},attrs:{"icon":"LEyeIcon","size":"28"}})],1),_c('feather-icon',{staticClass:"cursor-pointer mr-1 border-dotted feather-trash-icon",staticStyle:{"padding":"5px"},attrs:{"icon":"LTrashIcon","size":"28"},on:{"click":function($event){return _vm.deleteAttachment(data.item.id, data.index)}}})],1)]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'name'))+" ")]}},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mapDate(_vm.getValueFromGivenObjectByKey(item, 'created_at')))+" ")]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'mime_type'))+" ")]}},{key:"cell(category)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'attachment_category'))+" ")]}}],null,false,2917946223)}):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-content-center my-2"},[_c('b-spinner')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }