<template>
  <div>
    <div v-if="quote.id">
      <portal to="body-top">
        <h3 class="text-center font-weight-bolder mb-1">
          {{ $t(PURCHASE_ORDER_TITLE_PREFIX) }}
          {{ getValueFromGivenObjectByKey(quote, 'id') }}
          {{ $t(PURCHASE_ORDER_TITLE_SUFFIX) }}
          —
          {{ getValueFromGivenObjectByKey(quote, 'supplier.cust_name_dba') }}
        </h3>
      </portal>
      <order-information class="mb-1" />
      <stock-items class="mb-1" />
      <attachment class="mb-1" />
      <notes-and-totals class="mb-1" />
      <upload-invoice
        :style="{ pointerEvents: `${isSending ? 'none':'all'}`, opacity: `${isSending ? '0.5':'1'}` }"
        :is-loading="isSending"
        class="mb-1"
      />
      <form-bottom
        @reload="reload"
        @isLoading="reloadPage"
      />
    </div>

    <div v-else>
      <skeleton v-if="isLoading && !errorStatus" />
      <div v-else-if="errorStatus && !isLoading">
        <component-not-found
          :error-status="errorStatus"
          :title="PAGE_TITLE"
          :button-link="{name: 'home-orders-purchase-cr-list'}"
        />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import OrderInformation from '../components/create/purchase-order-for-supplier/OrderInformation.vue'
import StockItems from '../components/create/purchase-order-for-supplier/StockItems.vue'
import Attachment from '../components/create/purchase-order-for-supplier/Attachment.vue'
import NotesAndTotals from '../components/create/purchase-order-for-supplier/NotesAndTotals.vue'
import FormBottom from '../components/create/purchase-order-for-supplier/FormBottom.vue'
import UploadInvoice from '../components/create/purchase-order-for-supplier/UploadInvoice.vue'
import Skeleton from '../components/create/purchase-order-for-supplier/components/Skeleton.vue'
// eslint-disable-next-line import/no-cycle
import config from '../config'

export default {
  name: 'PurchaseOrderForSupplier',
  components: {
    Skeleton,
    Attachment,
    OrderInformation,
    StockItems,
    NotesAndTotals,
    FormBottom,
    UploadInvoice,
  },
  data() {
    return {
      errorStatus: null,
      isLoading: false,
      isSending: false,
    }
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  created() {
    this.reload()
  },
  methods: {
    reloadPage(isLoading) {
      console.log(isLoading)
      this.isSending = isLoading
    },
    reload() {
      this.isLoading = true
      this.$store.commit(`${this.MODULE_NAME}/GET`, {})

      this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params?.id)
        .then(({ data: { data } }) => {
          const products = data.products?.map(product => ({ ...product, tax: data.supplier.customer_detail.tax_rule.value })) ?? []

          this.$store.commit(`${this.MODULE_NAME}/GET`, { ...data, products })
        })
        .catch(({ response: { status } }) => {
          this.errorStatus = status
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  setup() {
    const {
      PAGE_TITLE,
      MODULE_NAME,
      RFQ_TITLE_PREFIX,
      RFQ_TITLE_SUFFIX,
      PURCHASE_ORDER_TITLE_PREFIX,
      PURCHASE_ORDER_TITLE_SUFFIX,
    } = config()

    return {
      PAGE_TITLE,
      MODULE_NAME,
      RFQ_TITLE_PREFIX,
      RFQ_TITLE_SUFFIX,
      getValueFromGivenObjectByKey,
      PURCHASE_ORDER_TITLE_PREFIX,
      PURCHASE_ORDER_TITLE_SUFFIX,
    }
  },
}
</script>
