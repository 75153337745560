<template>
  <div class="form-wrapper">
    <h3 class="font-weight-bolder mb-1">
      {{ $t(PURCHASE_ORDER_FOR_SUPPLIER_TITLE_ATTACHMENT) }}
    </h3>
    <b-table
      v-if="!isLoading"
      :items="receiveAttachments"
      :fields="uploadAttachmentTableCol"
      class="upload-attachment-table"
      :show-empty="true"
      :empty-text="'None found'"
    >
      <template #cell(action)="data">
        <div
          class="d-flex"
          style="gap: 8px"
        >
          <a @click="showAttachment(data.item)">
            <feather-icon
              icon="LEyeIcon"
              size="28"
              style="padding: 5px"
              class="cursor-pointer border-dotted feather-eye-icon"
            />
          </a>
          <feather-icon
            icon="LTrashIcon"
            size="28"
            style="padding: 5px"
            class="cursor-pointer mr-1 border-dotted feather-trash-icon"
            @click="deleteAttachment(data.item.id, data.index)"
          />
        </div>
      </template>
      <template #cell(name)="{ item }">
        {{ getValueFromGivenObjectByKey(item, 'name') }}
      </template>
      <template #cell(date)="{ item }">
        {{ mapDate(getValueFromGivenObjectByKey(item, 'created_at')) }}
      </template>
      <template #cell(type)="{ item }">
        {{ getValueFromGivenObjectByKey(item, 'mime_type') }}
      </template>
      <template #cell(category)="{ item }">
        {{ getValueFromGivenObjectByKey(item, 'attachment_category') }}

      </template>
    </b-table>
    <div
      v-if="isLoading"
      class="d-flex justify-content-center my-2"
    >
      <b-spinner />
    </div>
  </div>
</template>
<script>
import {
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import { mapDate, getValueFromGivenObjectByKey, getCurrentFormattedDate } from '@core/utils/utils'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import config from './config'
import mainConfig from '../../../config'

export default {
  name: 'Attachment',
  components: {
    BSpinner,
    BTable,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    attachmentCategoryListContact() {
      return this.$store.state.listModule.attachmentCategoryListContact ?? []
    },
    receiveAttachments() {
      return this.$store.state[this.MODULE_NAME].quote.attachments ?? []
    },
  },
  methods: {
    showAttachment(file) {
      if (file.id) {
        window.open(file.url, '_blank')
      } else {
        window.open(
          (window.URL || window.webkitURL).createObjectURL(file.file),
          '_blank',
        )
      }
    },
    getCategoryName(id) {
      return (
          this.attachmentCategoryListContact.find(item => +item.id === +id)
            ?.name ?? 'no category'
      )
    },
    deleteAttachment(id, index) {
      if (id) {
        this.isLoading = true
        this.$store
          .dispatch(`${this.MODULE_NAME}/deleteAttachment`, { id })
          .catch(() => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: i18n.t('Error Deleting This file', {
                  module: this.MODULE_NAME,
                }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(({ data: { data } }) => {
              this.$store.commit(`${this.MODULE_NAME}/SET_RECEIVE_ATTACHMENTS`, data.attachments)
            }).finally(() => {
              this.isLoading = false
            })
          })
      } else {
        this.receiveAttachments.splice(index, 1)
      }
    },
  },
  setup() {
    const { uploadAttachmentTableCol, PURCHASE_ORDER_FOR_SUPPLIER_TITLE_ATTACHMENT } = config()
    const { MODULE_NAME, MODULE_NAME_ATTACHMENT } = mainConfig()

    return {
      mapDate,
      MODULE_NAME,
      MODULE_NAME_ATTACHMENT,
      uploadAttachmentTableCol,
      getValueFromGivenObjectByKey,
      PURCHASE_ORDER_FOR_SUPPLIER_TITLE_ATTACHMENT,
    }
  },
}
</script>
<style lang="scss">
.upload-attachment-table {
    tbody tr td {
      padding: 5px 5px 5px 20px
    }

  & .b-table-empty-row {
    td {
      padding: 5px !important;

      div {
        margin: 0 !important;
      }
    }
  }
}
</style>
