<template>
  <b-collapse
    id="collapse-4"
    v-model="uploadInvoiceCollapseIsOpen"
    class="mt-2 form-wrapper"
  >
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="font-weight-bolder mb-1">
        {{ $t(PO_FOR_SUPPLIER_UPLOAD_INVOICE_TITLE) }}
      </h3>
      <feather-icon
        style="min-width: 37px; min-height: 37px"
        icon="LAddButtonIcon"
        size="24"
        class="cursor-pointer feather-add-icon mb-1"
        @click="$refs.refLFileUploadInput.click()"
      />
    </div>
    <div
      class="border-dashed cursor-pointer p-0"
      style="height: 130px; border-radius: 5px"
    >
      <div
        class="d-flex justify-content-center align-items-center h-100 w-100"
        @dragover.prevent
        @click="clickFileInput"
        @drop.prevent="dropped"
      >
        <h6 class="">
          <span v-if="!POInvoice.file">
            {{ PO_FOR_SUPPLIER_UPLOAD_INVOICE_DRAG_ZONE_TITLE }}
          </span>
          <span
            v-else
            class="d-flex align-items-center"
            style="gap: 10px"
          >
            <span>{{ POInvoice.file.name }}</span>
            <b-badge
              variant="secondary"
              @click.stop="clearInputValue"
            >
              <feather-icon
                icon="XIcon"
                size="18"
              />
            </b-badge>
          </span>
        </h6>
        <input
          v-show="false"
          ref="refLFileUploadInput"
          :accept="formattedAcceptProps"
          type="file"
          @input="fileInputChanged"
        >
      </div>
    </div>
    <b-modal
      id="attachment-upload-invoice"
      centered
      hide-header
      body-class="l-modal__body"
      footer-class="l-modal-footer"
      ok-title="Upload"
      ok-variant="success"
      cancel-variant="outline-primary"
      @ok="uploadFile"
    >
      <div class="l-modal__container">
        <b-row>
          <b-col cols="12">
            <h3 class="l-modal__title">
              {{ $t("What category is this file?") }}
            </h3>
          </b-col>
          <b-col>
            <l-select
              v-model="sendData.attachment_category_id"
              name="attachment_category_id"
              :field="{
                options: { transition: '' },
                store: 'attachmentCategoryListContact',
              }"
            />
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </b-collapse>
</template>

<script>
import {
  BCollapse, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import config from './config'
import mainConfig from '../../../config'

export default {
  name: 'UploadInvoice',
  components: {
    BRow,
    BCol,
    BBadge,
    BCollapse,
  },
  data() {
    return {
      allowedFormats: ['jpg', 'jpeg', 'png', 'tif', 'pdf', 'bmp'],
      sendData: {
        attachment_category_id: null,
        file: null,
      },
    }
  },
  computed: {
    uploadInvoiceCollapseIsOpen: {
      // getter
      get() {
        return this.$store.state[this.MODULE_NAME].uploadInvoiceCollapseIsOpen
      },
      // setter
      set(newValue) {
        this.$store.commit(`${this.MODULE_NAME}/SET_UPLOAD_INVOICE_COLLAPSE_IS_OPEN`, newValue)
      },
    },
    formattedAcceptProps() {
      return ['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']
    },
    POInvoice() {
      return this.$store.state[this.MODULE_NAME].POInvoice
    },
    attachmentCategoryListContact() {
      return this.$store.state.listModule.attachmentCategoryListContact ?? []
    },
  },
  methods: {
    showModalToChoseCategory() {
      this.$bvModal.show('attachment-upload-invoice')
    },
    hideModalToChoseCategory() {
      this.$bvModal.hide('attachment-upload-invoice')
    },
    dropped(e) {
      this.setUploadedAttachment(e.dataTransfer.files)
    },
    fileInputChanged(e) {
      this.setUploadedAttachment(e.target.files)
    },
    checkTheFileType(file) {
      const fileType = file.split('/')[1]
      let isErrorAvailable = false
      if (!this.allowedFormats.includes(fileType)) {
        isErrorAvailable = true
        this.$bvToast.toast(this.$t('File format is not accepted'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        this.hideModalToChoseCategory()
        this.isUploading = false
      }
      return isErrorAvailable
    },
    setUploadedAttachment(file) {
      // eslint-disable-next-line prefer-destructuring
      this.sendData.file = file[0]
      if (this.sendData.file.size > 4 * 1024 * 1024) {
        // 4MB limit
        this.hideModalToChoseCategory()
        this.$bvToast.toast(this.$t('File size exceeds the limit of 4MB. Please choose a smaller file'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        return
      }
      this.showModalToChoseCategory()
    },
    clearInputValue() {
      this.$store.commit(`${this.MODULE_NAME}/SET_UPLOADED_PO_INVOICE`, {})
    },
    clickFileInput() {
      this.$refs.refLFileUploadInput.click()
    },
    mappedFile() {
      return {
        ...this.sendData,
        attachment_category_id: this.sendData.attachment_category_id.id,
      }
    },
    uploadFile() {
      if (this.checkTheFileType(this.mappedFile().file?.type)) {
        return
      }
      this.$store.commit(`${this.MODULE_NAME}/SET_UPLOADED_PO_INVOICE`, this.mappedFile())
    },
  },
  setup() {
    const {
      PO_FOR_SUPPLIER_UPLOAD_INVOICE_TITLE,
      PO_FOR_SUPPLIER_UPLOAD_INVOICE_DRAG_ZONE_TITLE,
    } = config()

    const { MODULE_NAME } = mainConfig()

    return {
      MODULE_NAME,
      PO_FOR_SUPPLIER_UPLOAD_INVOICE_TITLE,
      PO_FOR_SUPPLIER_UPLOAD_INVOICE_DRAG_ZONE_TITLE,
    }
  },
}
</script>
