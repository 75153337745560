<template>
  <div>
    <portal to="body-top">
      <div class="d-flex justify-content-center">
        <b-skeleton
          width="50%"
          height="30px"
          class="mb-0"
        />
      </div>
      <quote-status-bar-skeleton />
    </portal>
    <div>
      <div class="form-wrapper mb-1">
        <h3 class="font-weight-bolder mb-1">
          {{ $t(ORDER_INFORMATION_TITLE) }}
        </h3>

        <div>
          <b-row>
            <b-col
              v-for="(_, indx) in Array(12)"
              :key="indx"
              cols="6"
            >
              <b-row class="mb-1">
                <b-col cols="6">
                  <b-skeleton
                    width="50%"
                    height="16px"
                    class="mb-0"
                  />
                </b-col>
                <b-col cols="6">
                  <b-skeleton
                    width="50%"
                    height="16px"
                    class="mb-0"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="form-wrapper mb-1">
        <h3 class="font-weight-bolder mb-1">
          {{ $t(STOCK_ITEMS_TITLE) }}
        </h3>

        <b-skeleton
          width="100%"
          height="34px"
          class="mb-0"
          style="margin-bottom: 2px !important"
        />
        <b-skeleton
          width="100%"
          height="34px"
          class="mb-2"
        />
      </div>
      <div>
        <b-row>
          <b-col cols="6">
            <div class="form-wrapper mb-1">
              <div class="mb-2">
                <h4 class="font-weight-bolder mb-1">
                  {{ $t(RFQ_SENT_BOTTOM_TITLE_ATTACHMENT_INTERNAL_ORDER_NOTES) }}
                </h4>

                <b-skeleton
                  width="70%"
                  height="14px"
                  class="mb-1"
                />
                <b-skeleton
                  width="70%"
                  height="14px"
                  class="mb-1"
                />
                <b-skeleton
                  width="70%"
                  height="14px"
                  class="mb-1"
                />

              </div>
              <div>
                <h4 class="font-weight-bolder mb-1">
                  {{ $t(RFQ_SENT_BOTTOM_TITLE_EXTERNAL_ORDER_NOTES) }}
                </h4>

                <b-skeleton
                  width="70%"
                  height="14px"
                  class="mb-1"
                />
                <b-skeleton
                  width="70%"
                  height="14px"
                  class="mb-1"
                />
                <b-skeleton
                  width="70%"
                  height="14px"
                  class="mb-1"
                />

              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="form-wrapper mb-1">
              <h4 class="font-weight-bolder mb-1">
                {{ $t(RFQ_SENT_BOTTOM_TITLE_ATTACHMENT) }}
              </h4>
              <div
                class="border p-1"
                style="border-radius: 5px"
              >
                <b-skeleton
                  width="100%"
                  height="20px"
                />

                <b-skeleton
                  width="100%"
                  height="20px"
                  class="m-0"
                />
              </div>
            </div>
          </b-col>
        </b-row>

      </div>
    </div>
  </div>
</template>

<script>
import { BSkeleton, BRow, BCol } from 'bootstrap-vue'
import QuoteStatusBarSkeleton from '@/views/main/orders/components/use-as-global/QuoteStatusBarSkeleton.vue'
import config from '../config'

export default {
  name: 'Skeleton',
  components: {
    BRow,
    BCol,
    BSkeleton,
    QuoteStatusBarSkeleton,
  },
  setup() {
    const {
      STOCK_ITEMS_TITLE,
      ORDER_INFORMATION_TITLE,
      RFQ_SENT_BOTTOM_TITLE_ATTACHMENT,
      RFQ_SENT_BOTTOM_TITLE_EXTERNAL_ORDER_NOTES,
      RFQ_SENT_BOTTOM_TITLE_ATTACHMENT_INTERNAL_ORDER_NOTES,
    } = config()

    return {
      STOCK_ITEMS_TITLE,
      ORDER_INFORMATION_TITLE,
      RFQ_SENT_BOTTOM_TITLE_ATTACHMENT,
      RFQ_SENT_BOTTOM_TITLE_EXTERNAL_ORDER_NOTES,
      RFQ_SENT_BOTTOM_TITLE_ATTACHMENT_INTERNAL_ORDER_NOTES,
    }
  },
}
</script>
