<template>
  <div>
    <div class="mb-4">
      <img
        :src="ErboLogo"
        alt="aw"
      >
    </div>
    <h3
      class="text-center font-weight-bolder mb-1"
      style="color: #646464"
    >
      {{ $t(PURCHASE_ORDER_TITLE_PREFIX) }}
      {{ getValueFromGivenObjectByKey(quote, 'id') }}
      {{ $t(PURCHASE_ORDER_TITLE_SUFFIX) }}
      —
      {{ getValueFromGivenObjectByKey(quote, 'supplier.cust_name_dba') }}
    </h3>
    <quote-status-bar
      :item="quote"
      class="mb-4"
    />
    <order-information />
    <stock-items-for-print />
    <notes-and-totals
      is-print
      style="margin-bottom: 200px"
    />
    <div style="display: flex; align-items: start; justify-content: space-between">
      <div style="width: 15%">
        <b v-for="item in appInfo">
          {{ item }}
        </b>
      </div>
      <div style="width: 25%; display: flex; flex-direction: column">
        <b>
          {{ getValueFromGivenObjectByKey(quote, 'dispatch_warehouse.label') }}
        </b>
        <b>
          {{ getValueFromGivenObjectByKey(quote, 'dispatch_warehouse.street') }}
        </b>
        <b>
          {{ getValueFromGivenObjectByKey(quote, 'dispatch_warehouse.label') }}
          {{ getValueFromGivenObjectByKey(quote, 'dispatch_warehouse.two_letter_code') }}
          {{ getValueFromGivenObjectByKey(quote, 'dispatch_warehouse.zip_code') }}
        </b>
      </div>
      <div style="width: 25%; display: flex; flex-direction: column">
        <b>
          {{ getValueFromGivenObjectByKey(quote, 'billing_address.city') }}
          {{ getValueFromGivenObjectByKey(quote, 'billing_address.street') }}
        </b>
        <b>
          {{ getValueFromGivenObjectByKey(quote, 'billing_address.city') }}
          {{ getValueFromGivenObjectByKey(quote, 'billing_address.zip') }}
        </b>
      </div>
    </div>

  </div>
</template>
<script>
import config from '@/views/main/orders/view/purchase/config'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import ErboLogo from '@/assets/images/ico/erbo-logo.svg'
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import OrderInformation from '../OrderInformation.vue'
import StockItemsForPrint from '../StockItemsForPrint.vue'
import NotesAndTotals from '../NotesAndTotals.vue'

export default {
  name: 'POForSupplierPrintTemplate',
  components: {
    QuoteStatusBar,
    OrderInformation,
    StockItemsForPrint,
    NotesAndTotals,
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
    appInfo() {
      return this.$store.state.app.appInfo
    },
  },
  setup() {
    const {
      PAGE_TITLE,
      MODULE_NAME,
      RFQ_TITLE_PREFIX,
      RFQ_TITLE_SUFFIX,
      PURCHASE_ORDER_TITLE_PREFIX,
      PURCHASE_ORDER_TITLE_SUFFIX,
    } = config()

    return {
      ErboLogo,
      PAGE_TITLE,
      MODULE_NAME,
      RFQ_TITLE_PREFIX,
      RFQ_TITLE_SUFFIX,
      PURCHASE_ORDER_TITLE_PREFIX,
      PURCHASE_ORDER_TITLE_SUFFIX,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>
