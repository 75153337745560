var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-wrapper"},[_c('h3',{staticClass:"font-weight-bolder mb-1",staticStyle:{"color":"#646464"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.STOCK_ITEMS_TITLE))+" ")]),_c('b-table',{staticClass:"purchase-order-stock-items",attrs:{"items":_vm.products,"fields":_vm.StockItemForPrintTableColumns,"show-empty":true,"empty-text":'None found',"foot-clone":""},scopedSlots:_vm._u([{key:"cell(action)",fn:function(ref){
var id = ref.item.id;
return [_c('feather-icon',{staticClass:"cursor-pointer border-dotted feather-trash-icon defaultIconColor",attrs:{"icon":"LEyeIcon","size":"24"},on:{"click":function($event){return _vm.gotoNewPage({name: 'home-catalog-general-info', params: { id: id } }, $event, true)}}})]}},{key:"cell(mpn)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'mpn' ))+" ")]}},{key:"cell(supplier_sku)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'supplier_sku' ))+" ")]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'amount' ))+" ")])]}},{key:"cell(subtotal)",fn:function(ref){
var ref_item = ref.item;
var discount = ref_item.discount;
var price = ref_item.price;
return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(price - (price * discount / 100)))+" ")]}},{key:"cell(discount)",fn:function(ref){
var ref_item = ref.item;
var discount = ref_item.discount;
var price = ref_item.price;
return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(price * discount / 100))+" ")]}},{key:"cell(taxes)",fn:function(ref){
var ref_item = ref.item;
var discount = ref_item.discount;
var price = ref_item.price;
var tax = ref_item.tax;
return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView((price - (price * discount / 100)) * tax / 100))+" ")]}},{key:"cell(total)",fn:function(ref){
var ref_item = ref.item;
var discount = ref_item.discount;
var price = ref_item.price;
var tax = ref_item.tax;
return [_c('div',{staticClass:"text-right",staticStyle:{"padding-right":"6px"}},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView((price - (price * discount / 100)) + (price - (price * discount / 100)) * tax / 100))+" ")])]}},{key:"foot(sku)",fn:function(){return [_vm._v(" "+_vm._s(' ')+" ")]},proxy:true},{key:"foot(mpn)",fn:function(){return [_vm._v(" "+_vm._s(' ')+" ")]},proxy:true},{key:"foot(supplier_sku)",fn:function(){return [_vm._v(" "+_vm._s(' ')+" ")]},proxy:true},{key:"foot(price)",fn:function(){return [_vm._v(" "+_vm._s(' ')+" ")]},proxy:true},{key:"foot(name)",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(_vm.TOTALS_TOTAL_PRICE_TITLE))+"S ")]},proxy:true},{key:"foot(amount)",fn:function(){return [_vm._v(" "+_vm._s(_vm.getTotalOF('amount'))+" ")]},proxy:true},{key:"foot(discount)",fn:function(){return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.totalSumOfDiscounts))+" ")]},proxy:true},{key:"foot(subtotal)",fn:function(){return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.totalSumOfSubtotals))+" ")]},proxy:true},{key:"foot(taxes)",fn:function(){return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.totalSumOfTaxes))+" ")]},proxy:true},{key:"foot(total)",fn:function(){return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.totalSumOfItem))+" ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }